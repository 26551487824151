import _ from './sendingMessage.module.css';
import { Fade, Box } from '@mui/material';
import PropTypes from 'prop-types';

export const SendingMessage = ({ text }) => (
  <Fade in={true} timeout={1000}>
    <Box>
      <form className={_.form}>
        <h2 className={_.form__title}>{text}</h2>
      </form>
    </Box>
  </Fade>
);

SendingMessage.propTypes = {
  text: PropTypes.string,
};
