import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import _ from './BurgerMenu.module.css';
import { ReactComponent as BurgerIcon } from './img/burger-menu.svg';

export const BurgerMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        min-width={30}
      >
        <BurgerIcon className={_.burgerIcon} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <NavLink
            to='/'
            className={
              ({ isActive }) => (isActive ? _.link__active : '')
            }
          >Главная</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href="/portfolio/" target="_blank">Резюме</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink
            to='/contacts'
            className={
              ({ isActive }) => (isActive ? _.link__active : '')
            }
          >Контакты</NavLink>
        </MenuItem>
      </Menu>
    </>
  );
};
