import _ from './Description.module.css';
import { ReactComponent as SpeedIcon } from './img/speed.svg';
import { ReactComponent as AdaptiveIcons } from './img/adaptive.svg';
import { ReactComponent as MovingIcons } from './img/moving.svg';

export const Description = () => (
  <section className={_.description}>
    <h2 className={_.description__title}>Сайт</h2>
    <ul className={_.description__items}>
      <li className={_.description__item}>
        <SpeedIcon
          className={
            _.description__item_img + ' ' + _.description__item_img_speed
          }
        />
        <h3 className={_.description__item_title}>Быстрый</h3>
        <p className={_.description__item_description}>Быстрое время загрузки</p>
      </li>
      <li className={_.description__item}>
        <AdaptiveIcons className={_.description__item_img} />
        <h3 className={_.description__item_title}>Отзывчивый</h3>
        <p className={_.description__item_description}>Работает на любом устройстве</p>
      </li>
      <li className={_.description__item}>
        <MovingIcons className={_.description__item_img} />
        <h3 className={_.description__item_title}>Динамический</h3>
        <p className={_.description__item_description}>Cайт должен быть анимированным</p>
      </li>
    </ul>

    <p className={_.description__description}>Верстка сайтов и продвижение</p>
    <p className={_.description__description}>Настройка Яндекс-Метрики и Googlе Аналитики</p>
  </section>
);
