import './App.css';
import { Header } from './components/header/Header';
import { Main } from './components/main/Main';
import { Footer } from './components/footer/Footer';
import { Route, Routes } from 'react-router-dom';
import { Contacts } from './components/contacts/Contacts';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/'
          element={
            <Main />
          } />
        <Route path='/contacts'
          element={
            <Contacts />
          } />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
