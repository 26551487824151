import _ from './Main.module.css';
import { Container } from '../сontainer/Container';
import { About } from './about/About';
import { Description } from './description/Description';
import { Fade, Box } from '@mui/material';
// import Box from '@mui/material/Box';

export const Main = () => {
  console.log();

  return (
    <main className={_.main}>
      <Container>
        <Fade in={true} timeout={2000}>
          <Box >
            <About />
            <Description />
          </Box>
        </Fade>
      </Container>
    </main>
  );
};
