
import { NavLink } from 'react-router-dom';
import { Container } from '../сontainer/Container';
import { BurgerMenu } from './burgerMenu/BurgerMenu';
import _ from './Header.module.css';
import { Fade, Box } from '@mui/material';


export const Header = () => {
  console.log();
  return (
    <header className={_.header}>
      <Container>
        <div className={_.header__container}>
          <nav className={_.header__nav}>
            <div className={_.header__burgerMenu}>
              <BurgerMenu />
            </div>
            <ul className={_.header__items}>
              <li className={_.header__item}>
                <NavLink
                  to='/'
                  className={
                    ({ isActive }) => (isActive ? _.link__active : '')
                  }
                >Главная</NavLink>
              </li>
              <li className={_.header__item + ' ' + _.header__display_none}>
                <a href="/portfolio/" target="_blank">Резюме</a>
              </li>
              <li className={_.header__item}>
                <NavLink
                  to='/contacts'
                  className={
                    ({ isActive }) => (isActive ? _.link__active : '')
                  }
                >Контакты</NavLink>
              </li>
            </ul>
          </nav>
          <Fade in={true} timeout={1000}>
            <Box>
              <h1 className={_.header__title}>Алексей Константинов</h1>
            </Box>
          </Fade>
        </div>
      </Container>
    </header>
  );
};

