import _ from './About.module.css';
import css3 from './img/css3.png';
import docker from './img/docker.png';
import git from './img/git.png';
import gulp from './img/gulp.png';
import html5 from './img/html5.png';
import java from './img/java.png';
import js from './img/JS.png';
import nodejs from './img/node-js.png';
import php from './img/php.png';
import postgresql from './img/postgresql.png';
import react from './img/react.png';
import redux from './img/redux.png';
import sass from './img/sass.png';
import webpack from './img/webpack.png';
import pug from './img/pug.png';

export const About = () => (
  <section className={_.about}>
    <h2 className={_.about__title}>Front-end developer</h2>
    <ul className={_.about__items}>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={html5}
          alt='html5'
          aria-label='html5'
          title='html5'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={css3}
          alt='css3'
          aria-label='css3'
          title='css3'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={js}
          alt='js'
          aria-label='js'
          title='js'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={pug}
          alt='pug'
          aria-label='pug'
          title='pug'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={sass}
          alt='scss'
          aria-label='scss'
          title='scss'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={git}
          alt='git'
          aria-label='git'
          title='git'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={webpack}
          alt='webpack'
          aria-label='webpack'
          title='webpack'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={react}
          alt='react'
          aria-label='react'
          title='react'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={redux}
          alt='redux'
          aria-label='redux'
          title='redux'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={gulp}
          alt='gulp'
          aria-label='gulp'
          title='gulp'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={nodejs}
          alt='node-js'
          aria-label='node-js'
          title='node-js'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={php}
          alt='php'
          aria-label='php'
          title='php'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={java}
          alt='java'
          aria-label='java'
          title='java'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={postgresql}
          alt='postgresql'
          aria-label='postgresql'
          title='postgresql'
        />
      </li>
      <li className={_.about__item}>
        <img
          className={_.about__item_img}
          src={docker}
          alt='docker'
          aria-label='docker'
          title='docker'
        />
      </li>
    </ul>
  </section>
);
