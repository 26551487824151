import _ from './Form.module.css';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState } from 'react';
import axios from 'axios';
import { Fade, Box } from '@mui/material';
import { SendingMessage } from './sendingMessage/SendingMessage';

export const Form = () => {
  const recaptchaRef = React.createRef();
  const [success, setSuccess] = useState(false);
  const [messege, setMessege] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (recaptchaValue) {
      const formData = new FormData();
      formData.append('fio', data.fio);
      formData.append('emailPhone', data.emailPhone);
      formData.append('message', data.message);
      formData.append('g-recaptcha', recaptchaValue);

      axios.post('/send.php', formData)
        .then((response) => {
          if (response.status === 200) {
            setSuccess(true);
            setMessege('Сообщение отправленно');
            setTimeout(() => {
              reset();
              setSuccess(false);
            }, 10000);
          }
        })
        .catch((error) => {
          console.log(error);
          setMessege(`Что - то пошло не так!\n ${error.message}\n  попробуйте позже`);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            setMessege('');
          }, 10000);
        });
    } else {
      console.log('stop capcha');
    }
  };

  const onChange = (value) => {
    // console.log('Captcha value:', value);
  };

  return (
    <>
      {!success ?
        <Fade in={true} timeout={1000}>
          <Box>
            <form className={_.form} onSubmit={handleSubmit(onSubmit)}>
              <h2 className={_.form__title}>Чем я&nbsp;могу помочь вам?</h2>
              <label>
                <input
                  className={_.form__input}
                  type='text'
                  id='fio'
                  name='fio'
                  {...register('fio', { required: true })}
                  placeholder='Введите как к вам обращятся'
                />
                {errors.fio && <p style={{ color: 'tomato' }}>Должно быть заполнено</p>}
              </label>

              <label>
                <input
                  className={_.form__input}
                  type='text'
                  id='emailPhone'
                  name='emailPhone'
                  {...register('emailPhone', { required: true })}
                  placeholder='Введите email или номер телефона'
                />
                {errors.emailPhone && (
                  <p style={{ color: 'tomato' }}>Должно быть заполнено</p>
                )}
              </label>

              <label>
                <textarea
                  className={_.form__textarea}
                  id='message'
                  name='message'
                  {...register('message', { required: true })}
                  placeholder='Введите сообщение'
                />
                {errors.message && (
                  <p style={{ color: 'tomato' }}>Должно быть заполнено</p>
                )}
              </label>
              <ReCAPTCHA
                className={_.form__recaptcha}
                ref={recaptchaRef}
                sitekey='6LcoA-gjAAAAAHqdB-Jzsloke-mHlnYh637JyMsI'
                onChange={onChange}
              />
              <input className={_.btn} type='submit' value='Отправить' />
            </form>
          </Box>
        </Fade> :
        <SendingMessage text={messege}/>
      }
    </>
  );
};
