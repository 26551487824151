/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import { Container } from '../сontainer/Container';
import _ from './Contacts.module.css';
import vk from './img/vk-sitesandbot.png';
import { ReactComponent as PhoneIcon } from './img/phone.svg';
import { ReactComponent as MailIcon } from './img/gmail.svg';
import { ReactComponent as TelegramIcon } from './img/telegram.svg';
import { ReactComponent as SkypeIcons } from './img/skype.svg';
import { ReactComponent as VkIcons } from './img/vk_icon.svg';
import { ReactComponent as GItHubIcons } from './img/github.svg';
import { Fade, Box, Grow } from '@mui/material';
import { Form } from './form/Form';

export const Contacts = () => (
  <main className={_.contacts__main}>
    <Container>
      <Fade in={true} timeout={1000}>
        <Box>
          <h2 className={_.contacts__title}>Контакты</h2>
        </Box>
      </Fade>
      <div className={_.contacts__content}>
        <div className={_.contacts__form}>
          <Form />
        </div>
        <ul className={_.contacts__items}>
          <li className={_.contacts__item}>
            <Grow in={true} timeout={1000}>
              <Box>
                <a href='tel:+79063835583' className={_.contacts__item_link}>
                  <PhoneIcon width={30} />
                  +79063835583
                </a>
              </Box>
            </Grow>
          </li>
          <li className={_.contacts__item}>
            <Grow in={true} timeout={1500}>
              <Box>
                <a
                  href='mailto:info@alekseykonstantinov.ru'
                  className={_.contacts__item_link}>
                  <MailIcon className={_.contacts__item_svgmail} width={30} height={30} />
                  info@alekseykonstantinov.ru
                </a>
              </Box>
            </Grow>
          </li>
          <li className={_.contacts__item}>
            <Grow in={true} timeout={2000}>
              <Box>
                <a href='https://t.me/Alex_kav' className={_.contacts__item_link}>
                  <TelegramIcon width={30} height={30} />
                  @Alex_kav
                </a>
              </Box>
            </Grow>
          </li>
          <li className={_.contacts__item}>
            <Grow in={true} timeout={2500}>
              <Box>
                <a
                  href='alekseikonstantinov'
                  className={_.contacts__item_link}
                  target="_blank">
                  <VkIcons className={_.contacts__item_socvk} width={30} height={30} />
                  vk.com/alekseikonstantinov
                </a>
              </Box>
            </Grow>
          </li>
          <li className={_.contacts__item}>
            <Grow in={true} timeout={2500}>
              <Box>
                <a
                  href='https://vk.com/sitesandbot'
                  className={_.contacts__item_link}
                  target="_blank">
                  <VkIcons className={_.contacts__item_socvk} width={30} height={30} />
                  vk.com/sitesandbot
                </a>
              </Box>
            </Grow>
          </li>
          <li className={_.contacts__item}>
            <Grow in={true} timeout={2500}>
              <Box>
                <a
                  href="skype:alex_kav78?chat"
                  className={_.contacts__item_link}
                >
                  <SkypeIcons width={33} height={33} />
                  alex_kav78
                </a>
              </Box>
            </Grow>
          </li>
          <li className={_.contacts__item}>
            <Grow in={true} timeout={2500}>
              <Box>
                <a
                  href='https://github.com/AlexeyKonstantinov78'
                  className={_.contacts__item_link}
                  target='_blank'>
                  <GItHubIcons width={33} height={33} />
                  github
                </a>
              </Box>
            </Grow>
          </li>
        </ul>
      </div>
    </Container>
  </main>
);
